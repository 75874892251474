import { putRequest } from "../../service/RequestAdapter";
import { AddTagProps } from "./types";
import QRScanner from '../qrscanner';
import './styles.scss'
import { useEffect, useState } from "react";

enum SetKandiTagResult {
    InProgress,
    Success,
    TagTaken,
    Error,
    NotSet
}

const AddTag: React.FunctionComponent<AddTagProps> = (props) => {

    const [setKandiTagResult, setSetKandiTagResult] = useState(SetKandiTagResult.NotSet);

    const callSetKandiTag = async function(tagId: string) {
        console.log("callSetKandiTag called, tag =" + tagId);
        try {
            const data = await putRequest(`tags/${tagId}`, { kandiId : props.userKandi.kandiId });
            console.log ("Success setting Tag!");
            console.log (data);
            setSetKandiTagResult(SetKandiTagResult.Success);
            if (props.onSetTag) {
                props.onSetTag(true);
            }
        } catch (err) {
            if (err?.response?.data?.name === "TagTaken") {
                console.log("Tag is already in use, please pick a different tag");
                setSetKandiTagResult(SetKandiTagResult.TagTaken);
            } else {
                console.error(err);
                setSetKandiTagResult(SetKandiTagResult.Error);
            }
            if (props.onSetTag) {
                props.onSetTag(false);
            }
        }
    }
        
    const callSetKandiTagFromURL = async function(tagURL: string) {
        setSetKandiTagResult(SetKandiTagResult.InProgress);
        const tagId = tagURL.substring(tagURL.lastIndexOf('/') + 1);
        callSetKandiTag(tagId);
    }

    useEffect(() => {
        if (props.tag) {
            callSetKandiTagFromURL(props.tag);
        }
    }, [props.tag]);

    let modalContent = null;
    switch(setKandiTagResult) {
        case SetKandiTagResult.NotSet:
            modalContent = <QRScanner onScan={(data) => callSetKandiTagFromURL(data)} />
            break;
        case SetKandiTagResult.InProgress:
            modalContent = "Adding tag to kandi, please wait..."
            break;
        case SetKandiTagResult.Success:
            modalContent = "Successfully added tag to kandi!"
            break;
        case SetKandiTagResult.TagTaken:
            modalContent = "Tag is already associated with another Kandi. Please try again with a different tag."
            break;
        case SetKandiTagResult.Error:
            modalContent = "Failed adding tag to kandi, please try again."
            break;
    }

    if (props.tag) {
        return <div></div>
    } else {
        return (
            <div className="modal">
                <div className="modal-content">
                    <h2>Add Tag</h2>
                    <div>
                        { modalContent }
                    </div>
                    <div className="actions">
                        <button className="close" onClick={e => props.onClose()}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        );
    }
};

export default AddTag;