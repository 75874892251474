import { useParams } from "react-router-dom";
import { publicContentUrl } from '../../config/ServiceConfig';
import useAsyncEffect from 'use-async-effect';
import { getRequest, postRequest } from '../../service/RequestAdapter';
import './styles.scss'
import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { KandiProps } from "./types";
import { Auth } from "aws-amplify";
import { AuthState } from '@aws-amplify/ui-components';
import SignInModal from '../../components/signInModal';
import OwnerHistory from '../../components/ownerHistory';
import { formatDate } from '../../utils/DateUtil';

/**
 * Transitions:
 * (Customer signed in, does not own Kandi)
 * NotSet -> ReadyToClaim -> InProgress -> Claimed
 * 
 * (Customer signed in, owns Kandi)
 * NotSet -> NotAvailable
 * 
 * (Customer not signed in, does not own kandi)
 * NotSet -> NotSignedIn -> InProgress -> Claimed
 * 
 * (Customer not signed in, owns Kandi)
 * - Customers who click claim kandi, log in through modal, then show a message
 * NotSet -> NotSignedIn -> AlreadyOwned
 */
enum KandiClaimState {
    NotSet,
    NotAvailable, // No tag present, or user already owns kandi
    NotSignedIn,
    ReadyToClaim,
    InProgress,
    Claimed,
    AlreadyOwned // user already owns kandi, but was not signed in so sees a different message then NotAvailable
}

const Kandi: React.FunctionComponent<KandiProps> = (props) => {
    let { vanityHandle } = useParams() as any;
    const tagId = (props.location.state as any)?.tagId;

    /** Hooks */
    const [authState, setAuthState] = useState<AuthState>();
    const [user, setUser] = useState<any | undefined>();
    const [kandiId, setKandiId] = useState("");
    const [kandiEvents, setKandiEvents] = useState<Array<any>>([]);
    const [kandiClaimState, setKandiClaimStateInternal] = useState(KandiClaimState.NotSet);
    const [showSignInModal, setShowSignInModal] = useState(false);

    const setKandiClaimState = (newState: KandiClaimState) => {
        if (![KandiClaimState.NotAvailable, KandiClaimState.AlreadyOwned, KandiClaimState.Claimed].includes(kandiClaimState)) {
            setKandiClaimStateInternal(newState);
        }
    }

    useEffect(() => {
        // Disable claim kandi if no tag was past
        if(!tagId) {
            setKandiClaimState(KandiClaimState.NotAvailable);
        }
    }, []);

    useEffect(() => {
        if (kandiEvents?.length && user) {
            if (user.username != kandiEvents[0].userName) {
                setKandiClaimState(KandiClaimState.ReadyToClaim);
            } else {
                //username matches kandi current owner
                setKandiClaimState(KandiClaimState.NotAvailable);
            }
        }
    }, [user, kandiEvents]);
    
    useAsyncEffect(async isMounted => {
        let data;
        try {
            data = await Auth.currentAuthenticatedUser();
        } catch (err) {
            console.log("User is not logged in.");
            data = null;
        }

        if (!isMounted()) return;
        if (data) {
            setAuthState(AuthState.SignedIn);
            setUser(data);
        } else {
            setAuthState(AuthState.SignedOut);
            setUser(null);
            setKandiClaimState(KandiClaimState.NotSignedIn);
        }
    }, [vanityHandle]);

    useAsyncEffect(async isMounted => {
        console.log("calling get Kandi for vanityHandle");
        let vanityHandleData;
        try {
            vanityHandleData = await getRequest(`vanityHandles/${vanityHandle}`);
        } catch (err) {
            console.error(`Failed to fetch vanityHandle [${vanityHandle}]`, err);
            return;
        }
        if (!vanityHandleData?.kandiId) {
            console.error(`Failed to fetch vanityHandle [${vanityHandle}]`);
            return;
        }

        console.log("Calling get kandi events");
        let kandiEventsData;
        try {
            kandiEventsData = await getRequest(`kandis/${vanityHandleData.kandiId}/events`);
        } catch (err) {
            console.error(`Failed to fetch kandi events for kandiId [${vanityHandleData.kandiId}]`, err);
            return;
        }
        if (!kandiEventsData?.events) {
            console.error(`Failed to fetch kandi events for kandiId [${vanityHandleData.kandiId}]`);
            return;
        }

        if (!isMounted()) return;
        setKandiId(vanityHandleData.kandiId);
        setKandiEvents(kandiEventsData.events);
    }, [vanityHandle]);
    /*******************/


    /** Event handlers */
    const createKandiNewOwnerEvent = async function () {
        console.log('Creating new kandi owner event');
        setKandiClaimState(KandiClaimState.InProgress);
        const result = await postRequest(`kandis/${kandiId}/events`, {
            eventType: "KANDI_NEW_OWNER",
            tagId
        });
        setKandiClaimState(KandiClaimState.Claimed);
        console.log(`Success adding new owner of kandi [${kandiId}]: [${JSON.stringify(result)}]`);
    }

    const claimKandiAfterSignIn = async function(nextAuthState: AuthState, authData?: any) {
        setShowSignInModal(false);

        if (kandiEvents?.length && (authData?.username != kandiEvents[0].userName)) {
            await createKandiNewOwnerEvent();
        } else {
            // User already owns this kandi
            setKandiClaimState(KandiClaimState.AlreadyOwned);
        }

        // Refresh user data
        setAuthState(AuthState.SignedIn);
        setUser(authData);
    }
    /*******************/
    

    let kandiPhoto = null;
    if (kandiId) {
        kandiPhoto = <img className="kandiPagePhoto" src={`${publicContentUrl}kandis/${kandiId}/0`} />;
    }

    let claimKandi = null;
    switch (kandiClaimState) {
        case KandiClaimState.NotSet:
        case KandiClaimState.NotAvailable:
            break;
        case KandiClaimState.NotSignedIn:
            claimKandi = <button onClick={()=>{setShowSignInModal(true)}}>Sign in / Sign up to Claim Kandi!</button>
            break;
        case KandiClaimState.ReadyToClaim:
            claimKandi = <button onClick={createKandiNewOwnerEvent}>Claim Kandi!</button>
            break;
        case KandiClaimState.InProgress:
            claimKandi = <span>Claiming kandi...</span>
            break;
        case KandiClaimState.Claimed:
            claimKandi = <span style={{color:'green'}}>Kandi Claimed! It will now show in your collection.</span>
            break;
        case KandiClaimState.AlreadyOwned:
            claimKandi = <span style={{color:'green'}}>Kandi is already yours, and will now show in your collection.</span>
            break;
    }

    let signInModal = null;
    if (showSignInModal) {
        signInModal = (
                <SignInModal 
                    onClose={()=>setShowSignInModal(false)}
                    onSignIn={claimKandiAfterSignIn}
                />
            )
    }

    return (
        <div className="kandiPageContainer">
            <div className="kandiPageContentContainer">
                <h2>{decodeURI(vanityHandle)}</h2>
                { claimKandi }
                { kandiPhoto }
                <OwnerHistory kandiEvents={kandiEvents} />
                { signInModal }
            </div>
        </div>
    );
}

export default withRouter(Kandi);
