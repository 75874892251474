import { apiGatewayUrlParam } from '../config/ServiceConfig';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import axios from 'axios';

const authHeaders = async function () {
  let currentSession: CognitoUserSession | null;
  try {
    currentSession = await Auth.currentSession();
  } catch (err) {
    // User is not signed in
    currentSession = null;
  }
  
  if (currentSession?.getIdToken()?.getJwtToken()) {
    const jwtToken1 = currentSession.getIdToken().getJwtToken();

    return {
      headers: { 'Authorization' :  jwtToken1 }
    };
  } else {
    return {
      headers: {}
    }
  }
}

export const getRequest = async function(apiURL: string): Promise<any> {
    if (!apiGatewayUrlParam) {
      throw new Error("apiGatewayUrlParam has not been set");
    }
    
    const apiRes = await axios.get(apiGatewayUrlParam + apiURL, await authHeaders());
    
    return apiRes.data;
}

export const postRequest = async function(apiURL: string, data: any = {}): Promise<any> {
  if (!apiGatewayUrlParam) {
    throw new Error("apiGatewayUrlParam has not been set");
  }

  const apiRes = await axios.post(apiGatewayUrlParam + apiURL, data, await authHeaders());
  
  return apiRes.data;
}

export const putRequest = async function(apiURL: string, data: any = {}): Promise<any> {
  if (!apiGatewayUrlParam) {
    throw new Error("apiGatewayUrlParam has not been set");
  }

  const apiRes = await axios.put(apiGatewayUrlParam + apiURL, data, await authHeaders());
  
  return apiRes.data;
}