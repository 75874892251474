import React, { useEffect, useState } from "react";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
import { SignInModalProps } from "./types";
import "./styles.scss";

const SignInModal: React.FunctionComponent<SignInModalProps> = (props) => {
    const [authState, setAuthState] = useState<AuthState>();
    const [user, setUser] = useState<any | undefined>();

    useEffect(() => {
        /** React.useState is lost after navigating to other pages, so read globally */
        Auth.currentAuthenticatedUser()
        .then(data => {
            setAuthState(AuthState.SignedIn);
            setUser(data);
        })
        .catch(err => {
            console.log(err)
            setAuthState(undefined);
            setUser(undefined);
        });

        return onAuthUIStateChange((nextAuthState, authData) => {
            console.log("onAuthUIStateChange called");
            setAuthState(nextAuthState);
            setUser(authData);
            if (nextAuthState === AuthState.SignedIn) {
                props.onSignIn(nextAuthState, authData);
            }
        });
    }, []);

    return authState === AuthState.SignedIn && user ? (
        <span></span>
    ) : (
        <div className="sign-in-modal">
            <div className="sign-in-modal-content">
                <div>
                    <AmplifyAuthenticator >
                        <AmplifySignUp
                        slot="sign-up"
                        formFields={[
                            { type: "username" },
                            { type: "email" },
                            { type: "password" },
                        ]} 
                        />
                    </AmplifyAuthenticator>
                </div>
                <div className="actions">
                    <button className="close" onClick={e => props.onClose()}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SignInModal;