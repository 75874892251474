import React from 'react';
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignUp, withAuthenticator } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import fetch from "node-fetch";
import { v4 as uuidv4 } from 'uuid';
import { apiGatewayUrlParam } from '../../config/ServiceConfig';
import CreateKandi from '../../components/createKandi';
import UserKandis from '../../components/userKandis';

const Home: React.FunctionComponent = () => {
    const [authState, setAuthState] = React.useState<AuthState>();
    const [user, setUser] = React.useState<any | undefined>();
    const [showCreateKandi, setShowCreateKandi] = React.useState(false);
    const [lastRefreshedKandi, setLastRefreshedKandi] = React.useState(Date.now());
  
    React.useEffect(() => {
        /** React.useState is lost after navigating to other pages, so read globally */
        Auth.currentAuthenticatedUser()
        .then(data => {
            setAuthState(AuthState.SignedIn);
            setUser(data);
        })
        .catch(err => {
            console.log(err)
            setAuthState(undefined);
            setUser(undefined);
        });

        return onAuthUIStateChange((nextAuthState, authData) => {
            console.log("onAuthUIStateChange called");
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    const callGetUserKandis = async function() {
        console.log("callGetUserKandis called");
        if (!apiGatewayUrlParam) {
          throw new Error("apiGatewayUrlParam has not been set");
        }
      
        const currentSession = await Auth.currentSession();
        const jwtToken1 = currentSession.getIdToken().getJwtToken();
        
        const apiRes = await axios.get( apiGatewayUrlParam + `users/${user.username}/kandis`, {
            headers: { 'Authorization' :  jwtToken1 }
          }
        );
        console.log ("Success calling getUserKandis!");
        console.log (apiRes.data);
    }

    let createKandi = showCreateKandi ? <CreateKandi setLastRefreshedKandi={setLastRefreshedKandi} /> : <span />

    return authState === AuthState.SignedIn && user ? (
        <div className="App">
            <div>Hello, {user.username}</div>
            <button onClick={callLambdaAPI}>
            Test Call lambda!
            </button>
            <button onClick={callGetUserKandis}>
            Test Call GetUserKandis!
            </button>
            <button onClick={e => setShowCreateKandi(!showCreateKandi)}>
                Let's add some Kandi!
            </button>
            { createKandi }
            <UserKandis userName={user.username} lastUpdated={lastRefreshedKandi} />
            <div style={{width:"100px", margin: "15px auto 15px auto"}} >
                <AmplifySignOut/>
            </div>
        </div>
    ) : (
        /** Documentation at https://docs.amplify.aws/ui/auth/authenticator/q/framework/react */
        <AmplifyAuthenticator >
            <AmplifySignUp
            slot="sign-up"
            formFields={[
                { type: "username" },
                { type: "email" },
                { type: "password" },
            ]} 
            />
        </AmplifyAuthenticator>
    )
};

async function callLambdaAPI() {
  console.log("callLambdaAPI called");
  if (!apiGatewayUrlParam) {
    throw new Error("apiGatewayUrlParam has not been set");
  }

  const currentSession = await Auth.currentSession();

  const jwtToken1 = currentSession.getIdToken().getJwtToken();
  axios.get( apiGatewayUrlParam + "ping", {
      headers: { 'Authorization' :  jwtToken1 }
  })
  .then(function(apiRes){
      console.log ("Success calling API!!");
      console.log (apiRes.data);
  })            
  .catch( function(err) { 
      console.log (err);
  } );
}


export default Home;
