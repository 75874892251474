import React, { useEffect, useState } from 'react';
import { UserKandisProps } from './types';
import { getRequest } from '../../service/RequestAdapter';
import useAsyncEffect from 'use-async-effect';
import { publicContentUrl } from '../../config/ServiceConfig';
import './styles.css';
import AddTag from '../addTagModal';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/DateUtil';

const UserKandis: React.FunctionComponent<UserKandisProps> = (props) => {
    const [userKandis, setUserKandis] = useState([] as Array<any>);
    const [selectedUserKandi, setSelectedUserKandi] = useState(null as any);

    useAsyncEffect(async isMounted => {
        console.log("callGetUserKandis called");
        const data = await getRequest(`users/${props.userName}/kandis`);
        console.log ("Success calling getUserKandis asyn effect!");
        if (!isMounted()) return;
        setUserKandis(data.kandis);
    }, [props.userName, props.lastUpdated]);

    const renderAddTagButton = function(userKandi: any) {
        if (userKandi.tagId) {
            return <Link to={`/t/${userKandi.tagId}`} >{userKandi.tagId}</Link>
            // return <span>Tag: {userKandi.tagId}</span>;
        } else {
            return (
                <button className="addTag" onClick={() => setSelectedUserKandi(userKandi)}>
                    Add tag
                </button>
            );
        }
    }
    
    let userKandisComponents;
    if (userKandis) {
        userKandisComponents = userKandis.map(userKandi => 
            <div key={userKandi.kandiId} className="kandiContainer">
                <div>{decodeURI(userKandi.vanityHandle)}</div>
                <div>Created on {formatDate(userKandi.timestamp)}</div>
                <img className="kandiPhoto" src={`${publicContentUrl}kandis/${userKandi.kandiId}/0`} />
                {renderAddTagButton(userKandi)}
            </div>
        );
    }

    let addTagModal;
    if (selectedUserKandi) {
        addTagModal = (
            <AddTag 
                userKandi={selectedUserKandi} 
                tag={props.tag}
                onClose={() => setSelectedUserKandi(null)}
                onSetTag={props.onSetTag}
            />
        );
    }

    return (
        <div className="userKandisContainer">
            {addTagModal}
            <h2>Kandis you currently own.</h2>
            {userKandisComponents}
        </div>
    );
}

export default UserKandis;