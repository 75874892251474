import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeComponent from './pages/home'
import MapComponent from './pages/map'
import NavComponent from './components/nav'
import TagComponent from './pages/tag';
import KandiComponent from './pages/kandi';
import EditProfileComponent from './pages/editProfile';
import ViewUserProfile from './pages/viewUserProfile';
import './App.css';

export default function App() {
  return (
    <Router>
      <div id="app">
        <NavComponent />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/t/:uuid">
            <TagComponent />
          </Route>
          <Route path="/k/:vanityHandle">
            <KandiComponent />
          </Route>
          <Route path="/u/:userName">
            <ViewUserProfile />
          </Route>
          <Route path="/map">
            <MapComponent />
          </Route>
          <Route path ="/profile">
            <EditProfileComponent />
          </Route>
          <Route path="/">
            <HomeComponent />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
