import { formatDate } from "../../utils/DateUtil";
import { OwnerHistoryProps } from "./types";
import { Link } from 'react-router-dom';

const OwnerHistory: React.FunctionComponent<OwnerHistoryProps> = (props) => {

    let kandiOwnerHistoryEntries = null;
    if (props.kandiEvents?.length) {
        kandiOwnerHistoryEntries = props.kandiEvents
            .map(kandiEvent => {
                // TODO - add location information to table
                return (
                    <tr key={kandiEvent.timestamp}>
                        <td>{formatDate(kandiEvent.timestamp)}</td>
                        <td>
                            <Link to={`/u/${kandiEvent.userName}`} >{kandiEvent.userName}</Link>
                        </td>
                    </tr>
                )
            });
    }

    let kandiOwnerHistoryTable = null;
    if (kandiOwnerHistoryEntries) {
        kandiOwnerHistoryTable = (
            <div>
                <h3>Ownership/Location History</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Date Created/Recieved</th>
                            <th>Owner</th>
                        </tr>
                    </thead>
                    <tbody>
                        {kandiOwnerHistoryEntries}
                    </tbody>
                </table>
            </div>
        )
    }

    return kandiOwnerHistoryTable;
}

export default OwnerHistory;