import { useState } from 'react';
import { ProfileProps } from './types'
import { Auth } from "aws-amplify";
import { AuthState } from '@aws-amplify/ui-components';
import useAsyncEffect from 'use-async-effect';
import { publicContentUrl } from '../../config/ServiceConfig';
import { getRequest, postRequest, putRequest } from '../../service/RequestAdapter';
import "./styles.scss";

enum ProfilePhotoState {
    LoadingAuth,
    NotSignedIn,
    NotSet,
    Set
}

const Profile: React.FunctionComponent<ProfileProps> = (props) => {
    const [profilePhotoState, setProfilePhotoState] = useState(ProfilePhotoState.LoadingAuth);
    const [authState, setAuthState] = useState<AuthState>(AuthState.Loading);
    const [user, setUser] = useState<any | undefined>();
    const [profilePhoto, setProfilePhoto] = useState(undefined as any);
    const [profilePhotId, setProfilePhotoId] = useState();
    const [profilePhotoKey, setProfilePhotoKey] = useState(Date.now());
    const [isSubmitting, setIsSubmitting] = useState(false);

    useAsyncEffect(async isMounted => {
        let userData;
        let profileData;
        try {
            userData = await Auth.currentAuthenticatedUser();
        } catch (err) {
            console.log("User is not logged in.");
            userData = null;
        }

        if (userData) {
            profileData = await getRequest(`users/${userData.username}/profile`);
        }

        if (!isMounted()) return;
        if (userData) {
            setAuthState(AuthState.SignedIn);
            setUser(userData);
            if (profileData?.profilePhotoId) {
                setProfilePhotoId(profileData.profilePhotoId);
                setProfilePhotoState(ProfilePhotoState.Set);
            } else {
                setProfilePhotoState(ProfilePhotoState.NotSet);
            }
        } else {
            setAuthState(AuthState.SignedOut);
            setUser(null);
            setProfilePhotoState(ProfilePhotoState.NotSignedIn);
        }
    }, []);

    const resetPhotoUpload = function() {
        setProfilePhotoKey(Date.now()); // Clears input field
        setIsSubmitting(false);
        setProfilePhoto(null);
    }

    const createProfilePhoto = async function(event: any) {
        console.log("createProfilePhoto called");
        if (profilePhoto) {
            setIsSubmitting(true);

            // Get presigned URL and upload photo to S3
            const uploadPhotoData: any = await postRequest(`users/${user.username}/photoURL`);
            const response = await fetch(uploadPhotoData.presignedURL, {
                method: "PUT", 
                body: profilePhoto,
                headers: {
                    "Cache-Control": "max-age=600" // 600s = 10minutes
                }
            });
            console.log("Photo upload response: " + JSON.stringify(response));

            // Set new photo as default profile picture
            await putRequest(`users/${user.username}/profile/picture`, { photoId: uploadPhotoData.photoId});

            setProfilePhotoId(uploadPhotoData.photoId);
            await new Promise(r => setTimeout(r, 5000)); // short sleep for eventual consistency

            setProfilePhotoState(ProfilePhotoState.Set);
            resetPhotoUpload();
        } else {
            console.warn("CreateProfilePhoto called but profilePhoto is not set.");
        }
    }

    let profilePhotoComponent;
    switch (profilePhotoState) {
        case ProfilePhotoState.Set:
            profilePhotoComponent = (
                <img 
                    src={`${publicContentUrl}users/${user.username}/${profilePhotId}`}
                />
            );
            break;
        case ProfilePhotoState.NotSet:
            profilePhotoComponent = null;
            break;
        case ProfilePhotoState.LoadingAuth:
            profilePhotoComponent = <span>Loading...</span>
            break;
        case ProfilePhotoState.NotSignedIn:
            profilePhotoComponent = <span>You must first sign in to edit your profile.</span>
    }

    const enablePhotoUpload = profilePhoto && !isSubmitting;
    return (
        <div className="App">
            <div className="setProfilePhotoContainer">
                <div>Set profile photo</div>
                <input 
                    type="file" 
                    onChange={e => setProfilePhoto(e.target.files?.[0])}
                    key={profilePhotoKey}
                />
                    <button type="button" onClick={createProfilePhoto} disabled={!enablePhotoUpload}>
                    Let's GO!
                </button>
            </div>
            { profilePhotoComponent }
        </div>
    );
}

export default Profile;