import React from 'react';

import { AmplifyAuthenticator, AmplifySignOut, AmplifySignUp, withAuthenticator } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import axios from 'axios';

const apiGatewayUrlParam = `https://api.${process.env.REACT_APP_DOMAIN}/service/`;

const Map: React.FunctionComponent = () => {
    const [authState, setAuthState] = React.useState<AuthState>();
    const [user, setUser] = React.useState<any | undefined>();
    const [msg, setMsg] = React.useState<string>();
    const [pos, setPos] = React.useState<GeolocationPosition>();

    React.useEffect(() => {
        /** React.useState is lost after navigating to other pages, so read globally */
        Auth.currentAuthenticatedUser()
        .then(data => {
            setAuthState(AuthState.SignedIn);
            setUser(data);
        })
        .catch(err => {
            console.log(err)
            setAuthState(undefined);
            setUser(undefined);
        });

        return onAuthUIStateChange((nextAuthState, authData) => {
            console.log("onAuthUIStateChange called");
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    if (!pos) {
      geoFindMe(setPos);
    }

    return authState === AuthState.SignedIn && user ? (
        <div id="map-page" className="App">
          <div>Hello, {user.username}</div>
          <button onClick={e => {showMap(e, pos)}}>Show map</button><br/>
          <form onSubmit={e => {checkIn(e, msg, pos) }}>
            <input name="msg" onChange={e => setMsg(e.target.value)}/>
            <input type="submit" value="Check-in Kandi!" />
          </form>
          <AmplifySignOut />
          <p id="status"></p>
          <GoogleMapsComponent />
        </div>
    ) : (
        /** Documentation at https://docs.amplify.aws/ui/auth/authenticator/q/framework/react */
        <AmplifyAuthenticator >
            <AmplifySignUp
            slot="sign-up"
            formFields={[
                { type: "username" },
                { type: "email" },
                { type: "password" },
            ]} 
            />
        </AmplifyAuthenticator>
    )
};

async function checkIn(e: any, msg: string|undefined, pos: GeolocationPosition|undefined) {
  console.log("checkIn called with msg " + msg + " and coords " + pos?.coords.latitude + ", " + pos?.coords.longitude);
  e.preventDefault();

  if (!apiGatewayUrlParam) {
    throw new Error("apiGatewayUrlParam has not been set");
  }

  const currentSession = await Auth.currentSession();

  const jwtToken1 = currentSession.getIdToken().getJwtToken();
  axios.post( apiGatewayUrlParam + "kandis/0/events", {
    message: msg,
    latitude: pos?.coords.latitude,
    longitude: pos?.coords.longitude
  }, {
    headers: { 'Authorization' :  jwtToken1 }
  })
  .then(function(apiRes){
      console.log ("Success calling API!!");
      console.log (apiRes.data);
  })
  .catch( function(err) {
      console.log (err);
  } );
}

const render = (status: Status): any => {
  if (status === Status.LOADING) return <Spinner />;
  if (status === Status.FAILURE) return <ErrorComponent />;
  return null;
};

const GoogleMapsComponent = () => (
  <Wrapper apiKey={`${process.env.REACT_APP_MAPS_API_KEY}`} render={render}>
    <div id="map"></div>
  </Wrapper>
);

const Spinner = () => (
  <div>Sit tight, map loading!</div>
)

const ErrorComponent = () => (
  <div>Uh oh, unable to load map</div>
)

async function showMap(e:any, pos:GeolocationPosition|undefined) {
  let map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
    center: { lat: 47.6131229, lng: -122.4121035 },
    zoom: 11,
  });
  if (pos) {
    new google.maps.Marker({position: {lat: pos.coords.latitude, lng: pos.coords.longitude}, map: map});
  }
}

async function geoFindMe(callback: Function) {
  console.log("geoFindMe called");
  const status = document.querySelector('#status') as HTMLElement;

  function success(position: GeolocationPosition) {
    const latitude  = position.coords.latitude;
    const longitude = position.coords.longitude;

    status.textContent = '';

    console.log("geoFindMe got coords: " + latitude + ", " + longitude);
    callback(position);
  }

  function error() {
    status.textContent = 'Unable to retrieve your location';
  }

  if (status) {
    if(!navigator.geolocation) {
      status.textContent = 'Geolocation is not supported by your browser';
    } else {
      status.textContent = 'Locating…';
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }
}

export default Map;
