import { useParams } from "react-router-dom";
import { ViewProfileProps } from "./types";
import { publicContentUrl } from '../../config/ServiceConfig';
import useAsyncEffect from 'use-async-effect';
import { getRequest } from "../../service/RequestAdapter";
import { useState } from "react";

const ViewProfile: React.FunctionComponent<ViewProfileProps> = (props) => {
    let { userName } = useParams() as any;

    const [profilePhotId, setProfilePhotoId] = useState();

    useAsyncEffect(async isMounted => {
        const profileData = await getRequest(`users/${userName}/profile`);

        if (!isMounted()) return;
        if (profileData?.profilePhotoId) {
            setProfilePhotoId(profileData.profilePhotoId);
        }
    }, []);

    // TODO - handle loading a default profile photo if none set
    let profilePhotoComp;
    if (profilePhotId) {
        profilePhotoComp = (
            <img 
                src={`${publicContentUrl}users/${userName}/${profilePhotId}`}
            />
        );
    }
    return (
        <div className="App">
            <div>{ userName }</div>
            { profilePhotoComp }
        </div>
    );
}

export default ViewProfile;