import React, { Component, FC } from "react";
import { Link, LinkProps, Route } from "react-router-dom";
import './styles.css';
import { NavLinkProps } from "./types";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';

/**
 * From https://stackoverflow.com/a/55465589/1197892
 * @param props 
 * @returns 
 */
const NavLink: FC<NavLinkProps> = (props) => {
    var { className, activeClassName, to, exact, ...rest } = props;

    return (
        <Route
          path={to}
          exact={exact}
          children={({ match }) => {
            if (match) {
              return <span className={className + " " + activeClassName}>{props.children}</span>;
            } else {
              return <Link className={className} to={to} {...rest}/>;
            }
          }}
        />
    )
}

export default function Nav() {
    const [authState, setAuthState] = React.useState<AuthState>();
    
    React.useEffect(() => {
        /** React.useState is lost after navigating to other pages, so read globally */
        Auth.currentAuthenticatedUser()
        .then(data => {
            setAuthState(AuthState.SignedIn);
        })
        .catch(err => {
            console.log(err)
            setAuthState(undefined);
        });

        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
        });
    }, []);

    return authState === AuthState.SignedIn ? (
      <nav>
        <ul>
          <li>
            <NavLink to="/" exact={true} className="navLink" activeClassName="selected">Home</NavLink>
          </li>
          <li>
            <NavLink to="/map" exact={true} className="navLink" activeClassName="selected">Map</NavLink>
          </li>
          <li>
            <NavLink to="/profile" exact={true} className="navLink" activeClassName="selected">Profile</NavLink>
          </li>
        </ul>
    </nav>
    ) : (
      <span></span>
    )
  }