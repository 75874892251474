import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import useAsyncEffect from 'use-async-effect';
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from "aws-amplify";
import { getRequest } from '../../service/RequestAdapter';
import UserKandis from '../../components/userKandis';
import SignInModal from '../../components/signInModal';

enum TagLookupState {
    NotSet,
    InProgress,
    KandiFound,
    KandiNotFound
}

const Tag: React.FunctionComponent = () => {
    let { uuid } = useParams() as any;

    const [tagLookupState, setTagLookupState] = useState(TagLookupState.NotSet);
    const [vanityHandle, setVanityHandle] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [authState, setAuthState] = useState<AuthState>(AuthState.Loading);
    const [user, setUser] = useState<any | undefined>();
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(Date.now());

    useAsyncEffect(async isMounted => {
        setTagLookupState(TagLookupState.InProgress);
        console.log(`Checking if tag ${uuid} is associated with Kandi`);
        const data = await getRequest(`tags/${uuid}`);
        console.log("Success looking up tag " + JSON.stringify(data));
        if (!isMounted()) return;
        if (data.kandiId && data.vanityHandle) {
            setTagLookupState(TagLookupState.KandiFound);
            setVanityHandle(data.vanityHandle);
            setRedirect(true);
        } else {
            setTagLookupState(TagLookupState.KandiNotFound);
        }
    }, [uuid, lastUpdated]);

    useAsyncEffect(async isMounted => {
        let data;
        try {
            data = await Auth.currentAuthenticatedUser();
        } catch (err) {
            console.log("User is not logged in.");
            data = null;
        }

        if (!isMounted()) return;
        if (data) {
            setAuthState(AuthState.SignedIn);
            setUser(data);
        } else {
            setAuthState(AuthState.SignedOut);
            setUser(null);
        }
    }, []);

    const refreshAfterSignIn = function (nextAuthState: AuthState, authData?: any) {
        setAuthState(AuthState.SignedIn);
        setUser(authData);
        setShowSignInModal(false);
    }

    if (redirect) {
        return <Redirect to={{
            pathname: `/k/${vanityHandle}`,
            state: { tagId: uuid }
        }} />
    } else {
        if (tagLookupState === TagLookupState.KandiNotFound) {
            let signInModal = null;
            if (showSignInModal) {
                signInModal = (
                        <SignInModal 
                            onClose={()=>setShowSignInModal(false)}
                            onSignIn={refreshAfterSignIn}
                        />
                    )
            }
            
            let userKandis;
            if (authState === AuthState.SignedIn && user) {
                userKandis = (
                    <div>
                        <div>Tag not registered yet. Select Kandi Below to register this tag to it.</div>
                        <UserKandis 
                            userName={user.username}
                            lastUpdated={Date.now()}
                            tag={uuid}
                            onSetTag={()=>{setLastUpdated(Date.now())}}
                        />
                    </div>
                    
                );
            } else {
                userKandis = (
                    <div>
                        <div>Tag not registered yet. Sign in / sign up to register tag.</div>
                        <button onClick={()=>{setShowSignInModal(true)}}>Sign in / sign up</button>
                    </div>
                );
            }
            return (
                <div className="App">
                    { signInModal }
                    { userKandis }
                </div>
            );
        } else {
            return null;
        }
    }
}

export default Tag;