import React, { useEffect, useState } from 'react';
import './styles.css';
import { CreateKandiProps } from './types';
import { getRequest, postRequest, putRequest } from '../../service/RequestAdapter';

const NOTIFICATION_TIME_OUT_IN_MS = 5000;

enum VanityHandleState {
    NotSet,
    NotValid,
    NotAvailible,
    Availible
}

const CreateKandi: React.FunctionComponent<CreateKandiProps> = (props) => {

    const [vanityHandle, setVanityHandle] = useState("");
    const [vanityHandleState, setVanityHandleState] = useState(VanityHandleState.NotSet);
    const [kandiPhoto, setKandiPhoto] = useState(undefined as any);
    const [kandiPhotoKey, setKandiPhotoKey] = useState(Date.now());
    const [notification, setNotification] = useState(undefined as any);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (notification?.timeout) {
            const timeout = setTimeout(() => {
                setNotification(undefined);
            }, notification.timeout);

            return () => clearTimeout(timeout);
        }
    }, [notification]);

    const reset = function() {
        setVanityHandle("");
        setVanityHandleState(VanityHandleState.NotSet);
        setKandiPhoto(null);
        setKandiPhotoKey(Date.now()); // Clears input field
        setIsSubmitting(false);
    }

    const createKandi = async function(event: any) {
        console.log("createKandi called");
        if (await isVanityHandleAvailable(vanityHandle) && kandiPhoto) {
            setIsSubmitting(true);
            setNotification({
                message: "Creating Kandi...",
                color: "green"
            })
            const kandiId = await callCreateKandi();
            await callSetVanityHandle(kandiId);
            await uploadPhoto(kandiId);
            setNotification({
                message: "Created Kandi success!",
                color: "green",
                timeout: NOTIFICATION_TIME_OUT_IN_MS
            });
            props.setLastRefreshedKandi(Date.now()); // Trigger reloading Kandi collection
            reset();
        } else {
            console.log("Vanity handle is already taken or photo not selected");
        }
    }

    const callCreateKandi = async function() {
        console.log("callCreateKandi called, vanityHandle=" + vanityHandle);

        const data = await postRequest(`kandis`, { vanityHandle });

        console.log ("Success calling CreateKandi!");
        console.log(JSON.stringify(data));
        return data.kandiId;
    }

    const isVanityHandleAvailable = async function(handleToCheck: string): Promise<boolean> {
        console.log("checkVanityHandleAvailable called, vanityHandle=" + handleToCheck);

        try {
            const data: any = await getRequest(`vanityHandles/${handleToCheck}`);

            if (data?.kandiId) {
                return false;
            } else {
                return true;
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    const callSetVanityHandle = async function(kandiId: string) {
        console.log("callSetVanityHandle called, vanityHandle=" + vanityHandle);
        try {
            const data = putRequest(`vanityHandles/${vanityHandle}`, { kandiId });
            
            console.log ("Success setting VanityHandle!");
            console.log (data);
        } catch (err) {
            if (err?.response?.data?.name === "VanityHandleTaken") {
                console.log("Vanity Handle is already in use, please pick a different handle");
                // TODO update UI to ask for new handle
            } else {
                console.error(err);
            }
        }
    }

    const isVanityHandleValid = function(vanityHandle: string) {
        return vanityHandle?.length >= 3;
    }

    const handleVanityHandleChange = async function(updatedVanityHandle: string) {
        setVanityHandle(updatedVanityHandle);
        if (isVanityHandleValid(updatedVanityHandle)) {
            if (await isVanityHandleAvailable(updatedVanityHandle)) {
                setVanityHandleState(VanityHandleState.Availible)
            } else {
                setVanityHandleState(VanityHandleState.NotAvailible)
            }
        } else {
            setVanityHandleState(VanityHandleState.NotValid)
        }
    }

    const uploadPhoto = async function(kandiId: string) {
        console.log("uploadPhoto called");
        const presignedURL = await callPhotoURL(kandiId);
        const response = await fetch(presignedURL, { method: "PUT", body: kandiPhoto });
        console.log("Photo upload response: " + JSON.stringify(response));
    }
    
    const callPhotoURL = async function(kandiId: string): Promise<string> {
        const data: any = await postRequest(`kandis/${kandiId}/photoURL`);
        console.log ("Success calling photoURL!");
        console.log (data);
        return data.presignedURL as string;
    }

    let vanityHandleAvailability;
    switch(vanityHandleState) {
        case VanityHandleState.NotSet:
            vanityHandleAvailability = <span></span>;
            break;
        case VanityHandleState.NotValid:
            vanityHandleAvailability = <span style={{color:"red"}}>Must be 3 or more characters.</span>
            break;
        case VanityHandleState.NotAvailible:
            vanityHandleAvailability = <span style={{color:"red"}}>Not availible</span>
            break;
        case VanityHandleState.Availible:
            vanityHandleAvailability = <span style={{color:"green"}}>Availible</span>
            break;
    }

    const submitEnabled = VanityHandleState.Availible === vanityHandleState && kandiPhoto && !isSubmitting;

    const notificationMessage = notification ? 
        (<div style={{color:notification.color}}>{notification.message}</div>) : 
        (<span></span>);

    return (
        <div className="createKandiContainer">
             <form>
                <label>
                    Give your Kandi a name?
                    <input 
                        type="text" 
                        name="vanityHandle"
                        value={vanityHandle}
                        onChange={e => handleVanityHandleChange(e.target.value)}
                    />
                    {vanityHandleAvailability}
                </label>
                <label>
                    Select Kandi Photo:
                    <input 
                        type="file" 
                        onChange={e => setKandiPhoto(e.target.files?.[0])}
                        key={kandiPhotoKey}
                    />
                </label>
                <button type="button" onClick={createKandi} disabled={!submitEnabled}>
                    Let's GO!
                </button>
            </form>
            {notificationMessage}
        </div>
    );
}


export default CreateKandi;